<div class="page-container">
  <app-header></app-header>
  <div *ngIf="!isLoading" class="bolt-container flex-container">
    <div>
      <h2 class="text-center">Before placing your order...</h2>

      <div class="bolt-row">
        <div class="bolt-col">
          <ol class="info-list">
            <li *ngIf="deviceName.toLowerCase() === 'resideo'">You will be taken to our trusted partners site to
              complete checkout</li>
            <li *ngIf="deviceName.toLowerCase() !== 'resideo'">You will be taken to the {{deviceName}} site to complete
              checkout</li>
            <li>Make sure you have the device shipped to the address associated with your home policy</li>
            <li>Don't forget, your device must be installed within <b>{{ discountDaysString }}</b> to
              maintain
              your discount!
            </li>
          </ol>
        </div>
      </div>

      <div *ngIf="termsError" class="bolt-row checkbox-error-margin">
        <div class="icon-spacing">
          <bolt-icon name="exclamation-circle-filled" color="cherry-red"></bolt-icon>
        </div>
        <div class="bolt-col">
          <i>{{ AGREE_TO_TERMS_ERROR }}</i>
        </div>
      </div>
      <div class="bolt-row">
        <div class="bolt-col">
          <bolt-checkbox class="checkbox-margin"
            (change)="agreedToTerms = !agreedToTerms; checkAgreedToTerms(agreedToTerms)" value="agree" required>
            I agree to Nationwide and their corresponding partners <a id="TaC"
              href="https://www.nationwide.com/personal/insurance/homeowners/smart-home/terms-2" target="_blank">Terms &
              Conditions</a>
          </bolt-checkbox>
        </div>
      </div>
    </div>

    <div class="bolt-row button-col-margin pb-10">
      <div class="bolt-col">
        <bolt-button class="button-margin" type="primary" width="full" (click)="goToCheckout(agreedToTerms)">
          Continue to checkout
        </bolt-button>
      </div>
    </div>
  </div>
  <bolt-waiting-indicator *ngIf="isLoading" class="mt-10 pt-10">Submitting your order...</bolt-waiting-indicator>
  <app-footer></app-footer>
</div>