import { Device, VendorName } from "./types/device"

export enum QueryParameters {
  DataCollectionId = 'dataCollectionId'
};

export enum UserPrivilege {
  Unauthenticated = 0,
  User = 1,
  Admin = 2
};

export const LeakBotDevice: Device = {
  shortName: VendorName.LEAKBOT,
  fullName: 'LeakBot leak detector',
  vendorName: 'LeakBot',
  description: 'Stop those runny leaks with LeakBot for the betterment of your home.',
  cost: 279.99,
  discount: 100,
  discountDays: 5,
  selected: true,
  installationMethod: 'order',
  deviceTypeId: '',
  deviceType: '',
  offerType: '',
  partnerMemberId: '',
  options: {
    orderOptions: [
      {
        name: 'order',
        buttonText: 'Order',
        selected: true,
      }
    ]
  },
}

export const PhynDevice: Device = {
  shortName: VendorName.PHYN,
  fullName: 'Phyn water monitoring',
  vendorName: 'Phyn',
  description: 'Some text about what Ting can do for you.',
  cost: 279.99,
  discount: 75,
  discountDays: 3,
  selected: false,
  installationMethod: 'order',
  deviceTypeId: '',
  deviceType: '',
  offerType: '',
  partnerMemberId: '',
  options: {
    orderOptions: [
      {
        name: 'order',
        buttonText: 'Order',
        selected: true,
      }
    ]
  },
}