import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Device, DeviceOfferType, OrderOption } from 'src/app/core/types/device';
import { DeviceHelpersService } from 'src/app/core/services/helpers/device-helpers.service';
import { DeviceInfo, DeviceStaticInfo, HowItWorksCardInfo, VideoInfo } from 'src/app/core/types/deviceInfo';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SmartHomeProduct } from 'src/app/core/types/product';
import { UserProfile } from 'src/app/core/types/userProfile';
import { FeatureFlagService, FeatureFlags } from 'src/app/core/services/feature-flag.service';
import { ProgramService } from 'src/app/core/services/program/program.service';
import { DeviceService } from 'src/app/core/services/device/device.service';
import { DeviceRecommendation } from 'src/app/core/types/http/deviceRecommendation';
import { HttpError } from 'src/app/core/types/http/error';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { UserPrivilege } from '../../core/constants';

@Component({
  selector: 'app-device-landing',
  templateUrl: './device-landing.component.html',
  styleUrls: ['./device-landing.component.less']
})
export class DeviceLandingComponent {
  @ViewChild('howItWorks') public howItWorksElement!: ElementRef;

  public SmartHomeProduct = SmartHomeProduct;

  profile: UserProfile = {} as UserProfile;
  deviceInfo: DeviceInfo = {} as DeviceInfo;
  device: Device = {} as Device;
  selectedDevices: Device[] = [];
  featureFlags: FeatureFlags = {} as FeatureFlags;
  isLoading: boolean = true;
  deviceOptionButtonText: string = '';
  howDeviceWorksVideo: VideoInfo | undefined;
  cardInfo: HowItWorksCardInfo[] = [];
  howDeviceInstalledVideo: VideoInfo | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService,
    private featureFlagService: FeatureFlagService,
    private deviceHelperService: DeviceHelpersService,
    private programService: ProgramService,
    private navigationService: NavigationService,
    private deviceService: DeviceService,
    private cdref: ChangeDetectorRef) {
    this.profileService.profile$.subscribe((profile) => {
      this.profile = profile;
    });
    this.featureFlagService.featureFlags$.subscribe((featureFlags) => {
      this.featureFlags = featureFlags;
    });
  }

  get isLocalAdmin(): boolean {
    return this.profile.privilegeLevel === UserPrivilege.Admin && window.location.hostname === 'localhost'
  }

  get validNumberOfDevices(): boolean {
    return this.selectedDevices.length === 1
  }

  ngOnInit(): void {
    if (this.profile) {
      this.activatedRoute.queryParamMap.subscribe((params: any) => {
        if (params.params.dataCollectionId) {
          if (this.profile) this.profile.dataCollectionId = params.params.dataCollectionId;
          if (this.profile.policyNumber) {
            this.getDeviceInfo(this.profile.policyNumber);
          } else {
            this.programService.getPolicyNumber(params.params.dataCollectionId).then(async (policyResponse: any) => {
              if (this.profile) {
                this.profile.policyNumber = policyResponse.policyNumber;
                this.profile.policyType = policyResponse.policyType;
              }
              this.getDeviceInfo(policyResponse.policyNumber);
            }).catch((error: HttpError) => { this.navigationService.redirectError(error) });
          }
        }
      });
    }
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges()
  }

  getDeviceInfo(policyNumber: string): void {
    if (policyNumber) {
      if (this.isLocalAdmin) {
        this.localSetup()
        this.isLoading = false
      } else {
        this.deviceService.getDeviceRecommendations(policyNumber, 'DEVICE_RECOMMENDATION').then((recommendationResponse: DeviceRecommendation[]) => {
          // For MVP2, only 1 device SHOULD be recommended. In the future, this will need to change.
          if (recommendationResponse && recommendationResponse.length === 1) {
            if (this.profile) {
              this.profile.devices = [new Device(recommendationResponse[0], true)];
              this.device = this.profile.devices[0];
              if (this.device) {
                this.profile.currentDevice = this.device.shortName;
                this.deviceInfo = DeviceStaticInfo[this.device.shortName];
                this.device.discountDays = this.deviceInfo.numberOfDaysToInstall;
                this.deviceOptionButtonText = this.getDeviceOrderOptionButtonText(this.deviceInfo);
                this.howDeviceWorksVideo = this.getHowDeviceWorksVideoInfo();
                this.cardInfo = this.getHowDeviceWorksCardInfo();
                this.howDeviceWorksVideo = this.getHowDeviceInstalledVideoInfo();
              }
              this.selectedDevices = this.deviceHelperService.getSelectedDevices(this.profile.devices ? this.profile.devices : []);
            }
          }
          this.isLoading = false;
        }).catch((error: HttpError) => { this.navigationService.redirectError(error) });
      }
    }
  }

  navigate(validNumberOfDevices: boolean): void {
    let navigationUrl: string = 'landing/shipping-confirmation';
    if (validNumberOfDevices) {
      if (this.device.offerType !== DeviceOfferType.COMPLIMENTARY) {
        navigationUrl = 'landing/additional-order-info';
      }
      this.router.navigateByUrl(navigationUrl);
    }
  }

  getDeviceOrderOptionButtonText(deviceInfo: DeviceInfo): string {
    let buttonText: string | undefined;
    let deviceOption: OrderOption | undefined;
    deviceOption = deviceInfo.deviceOptions.orderOptions.find((device: any) => {
      return device.selected;
    });
    buttonText = deviceOption?.buttonText;

    return buttonText ?? 'Order';
  }

  isExistingMember(profile: UserProfile | undefined): string {
    try {
      if (profile?.customer.isExistingMember) {
        return "Welcome Nationwide members";
      } else {
        throw new Error("Why isn't the 'isExistingMember' field set?");
      }
    } catch (error) {
      return "";
    }
  }

  orderButtonOpacity(selectedDevices: Device[]): string {
    return selectedDevices.length === 1 ? '' : 'container-disabled'
  }

  enterKeyPressed(event: any, validNumberOfDevices: boolean): void {
    if (event.keyCode === 13) {
      this.navigate(validNumberOfDevices);
    }
  }

  getHowDeviceWorksVideoInfo(): VideoInfo | undefined {
    return this.deviceInfo?.howDeviceWorksValues.howDeviceWorksVideo;
  }

  getHowDeviceInstalledVideoInfo(): VideoInfo | undefined {
    return this.deviceInfo?.deviceInstallationVideo;
  }

  getHowDeviceWorksCardInfo(): HowItWorksCardInfo[] | [] {
    return this.deviceInfo?.howDeviceWorksValues?.cardInfo
      ? this.deviceInfo.howDeviceWorksValues.cardInfo
      : [];
  }

  // local development with mock user and device data
  localSetup() {
    console.log('local data flow')
    this.device = this.profile.devices[0]
    this.profile.currentDevice = this.device.shortName
    this.deviceInfo = DeviceStaticInfo[this.device.shortName]
    this.device.discountDays = this.deviceInfo?.numberOfDaysToInstall ?? 99
    this.deviceOptionButtonText = this.getDeviceOrderOptionButtonText(this.deviceInfo)
    this.howDeviceWorksVideo = this.getHowDeviceWorksVideoInfo()
    this.cardInfo = this.getHowDeviceWorksCardInfo()
    this.howDeviceWorksVideo = this.getHowDeviceInstalledVideoInfo()
    this.selectedDevices = [this.profile.devices[0]]
  }
}
