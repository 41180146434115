import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Address, UserProfile } from '../types/userProfile';
import { Device, VendorName } from '../types/device';
import { Survey } from '../types/userProfile';
import { LeakBotDevice, UserPrivilege } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private profileSubject$: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(new UserProfile());
  public readonly profile$: Observable<UserProfile> = this.profileSubject$.asObservable();

  private stubAddresses: Address[] = [
    {
      city: "Columbus",
      state: "OH",
      street: "123 Street st",
      zipCode: "43215",
      isPolicyAddress: true,
      selected: false,
    },
    {
      city: "Cinci",
      state: "OH",
      street: "123 Street st",
      zipCode: "43215",
      isPolicyAddress: false,
      selected: false,
    }
  ];

  constructor() {
    this.profileSubject$.next(new UserProfile());
    // this.profileSubject$.next(this.stubUserProfile(true, true, true, UserPrivilege.Admin));
    // this.stubSurveyInfo();
  }

  private stubUserProfile(
    isStubProgramData: boolean,
    isStubCustomerData: boolean,
    isStubDeviceData: boolean,
    privilegeLevel: UserPrivilege = 0
  ): UserProfile {
    const userProfile: UserProfile = new UserProfile()
    if (!isStubProgramData && !isStubCustomerData && !isStubDeviceData) {
      return userProfile
    }
    if (isStubProgramData) {
      userProfile.currentDevice = 'leakbot'
      userProfile.policyNumber = '123'
      userProfile.dataCollectionId = '99cb95ff-0d9a-4704-a02f-015d2ac1f6aa'
    }
    if (isStubCustomerData) {
      userProfile.customer = {
        firstName: 'Tony',
        lastName: 'Tiger',
        email: 'theyregreat@gmail.com',
        phone: '5555555555',
        addresses: this.stubAddresses,
        isExistingMember: true,
      }
    }
    if (isStubDeviceData) {
      userProfile.devices = this.stubMultipleDevices()
    }
    userProfile.survey = this.stubSurveyInfo()
    userProfile.privilegeLevel = privilegeLevel
    return userProfile
  }

  stubSurveyInfo(): Survey[] {
    return [
      {
        question: 'What did you like most about this offer?',
        answers: [
          {
            answer: 'The offer came from my insurance company',
            selected: false
          },
          {
            answer: 'The device is useful to me',
            selected: false
          },
          {
            answer: 'The device was no additional charge',
            selected: false
          }
        ]
      }
    ]
  }

  private stubMultipleDevices(): Device[] {
    return [LeakBotDevice]
  }
}
